import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Textarea.css';

const Textarea = (
  {
    id,
    action,
    value,
    className,
    maxLength,
    placeholder,
    onFocus,
    onBlur,
    disabled,
  },
  context,
) => {
  const rootStyle = classNames(styles.root, styles[className], {
    [styles.errored]: context.errored,
    [styles.inactive]: context.inactive,
  });

  return (
    <textarea
      id={id}
      placeholder={placeholder}
      maxLength={maxLength}
      className={rootStyle}
      type={'text'}
      onChange={(e) => action(e.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      disabled={disabled}
      data-gramm_editor={false} // disables grammarly plugin that causes the bug onFocus
    />
  );
};
Textarea.displayName = 'Textarea';
Textarea.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

Textarea.defaultProps = {
  maxLength: null,
  disabled: false,
};

Textarea.contextTypes = {
  errored: PropTypes.bool,
  inactive: PropTypes.bool,
};

export default Textarea;
