import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import forceFocus from 'hocs/forceFocus';
import arrow from 'assets/icons/arrow.svg';
import SharedSelection from 'shared/shared-component/components/Selection';
import styles from './Selection.css';

const messages = defineMessages({
  selectText: {
    id: 'Selection.select.text',
    defaultMessage: 'Please Select',
  },
});

class Selection extends Component {
  static propTypes = {
    id: PropTypes.string,
    action: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectionDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultMessage: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    setFocusRef: PropTypes.func,
    className: PropTypes.string,
    simple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    intl: intlShape.isRequired,
    nonTabbable: PropTypes.bool,
    disabled: PropTypes.bool,
    rootStyle: PropTypes.object,
    rootClassName: PropTypes.string,
    smallArrow: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    simple: false,
    fullWidth: false,
    disabled: false,
    smallArrow: false,
  };

  render() {
    const {
      id,
      value,
      items,
      onFocus,
      setFocusRef,
      className,
      nonTabbable,
      rootStyle,
      rootClassName,
      disabled,
      simple,
      fullWidth,
      smallArrow,
      selectionDefault,
      intl: { formatMessage },
      defaultMessage,
      action,
      onChange,
    } = this.props;

    return (
      <SharedSelection
        id={id}
        value={value}
        items={items}
        onFocus={onFocus}
        setFocusRef={setFocusRef}
        className={className || styles.selection}
        nonTabbable={nonTabbable}
        rootStyle={rootStyle}
        rootClassName={rootClassName}
        disabled={disabled}
        simple={simple}
        fullWidth={fullWidth}
        smallArrow={smallArrow}
        selectionImage={arrow}
        selectionDefault={selectionDefault}
        defaultMessage={
          defaultMessage
            ? formatMessage(defaultMessage)
            : ` -- ${formatMessage(messages.selectText)} -- `
        }
        action={action}
        onChange={onChange}
      />
    );
  }
}

export const unHOCed = Selection;
export default forceFocus(injectIntl(Selection));
