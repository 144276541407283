import React from 'react';
import PropTypes from 'prop-types';
import Checklist from '../Checklist';
import styles from './ChecklistGroup.css';

const ChecklistGroup = ({ id, groups, disabled, checkImage, circleImage }) => (
  <div id={id} className={styles.root}>
    {groups.map((group, index) => (
      <div key={`ChecklistGroup--${id || 'noId'}--${index}`}>
        <div className={styles.groupName}>{group.label}</div>
        <Checklist
          action={group.action}
          value={group.value}
          items={group.items}
          id={group.id}
          disabled={disabled}
          checkImage={checkImage}
          circleImage={circleImage}
        />
      </div>
    ))}
  </div>
);

ChecklistGroup.displayName = 'ChecklistGroup';

ChecklistGroup.propTypes = {
  id: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.object).isRequired,
      action: PropTypes.func.isRequired,
      value: PropTypes.object,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  checkImage: PropTypes.string,
  circleImage: PropTypes.string,
};

ChecklistGroup.defaultProps = {
  disabled: false,
};

export default ChecklistGroup;
