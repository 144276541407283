import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';
import styles from './Checkbox.css';

class Checkbox extends Component {
  render() {
    const {
      selected,
      className,
      disabled,
      mouseover,
      checkImage,
      circleImage,
    } = this.props;

    const rootStyle = classNames.bind(styles, [className])({
      root: true,
      isSelected: selected,
      disabled,
      mouseover,
    });

    const checkStyle = classNames.bind(
      styles,
      'check',
    )({
      mouseoverCheck: mouseover,
    });

    const circleStyle = classNames.bind(
      styles,
      'circle',
    )({
      selectedCircle: selected,
    });

    return (
      <div className={rootStyle}>
        <div className={styles.checkWrapper}>
          <SVGInline className={checkStyle} svg={checkImage} />
        </div>
        <div className={styles.circleWrapper}>
          <SVGInline className={circleStyle} svg={circleImage} />
        </div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  selected: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  mouseover: PropTypes.bool,
  checkImage: PropTypes.string,
  circleImage: PropTypes.string,
};

Checkbox.defaultProps = {
  selected: false,
  disabled: false,
  mouseover: false,
};

export default Checkbox;
