import React from 'react';
import PropTypes from 'prop-types';
import SharedSpinner from 'shared/shared-component/components/Spinner';
import SpinningWheel from 'assets/icons/spinning-wheel.svg';
import styles from './Spinner.css';

const Spinner = (props) => {
  return (
    <SharedSpinner
      data-testid='shared.spinner'
      {...props}
      icon={SpinningWheel}
      className={styles[props.variant]}
    />
  );
};

Spinner.propTypes = {
  variant: PropTypes.oneOf(['inverse', 'default']),
};

export default Spinner;
