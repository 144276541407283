import chroma from 'chroma-js';
import Canvg from 'canvg';
import { isIE } from 'react-device-detect';

import template from '../styles/brandColor.tpl.css';

const BRAND_COLOR_STYLESHEET_ID = 'brandColor';
const DEFAULT_BRAND_COLOR = '#00AAE5';

const manipulate = (color) => {
  return {
    light: chroma(color).set('hsl.l', 0.9).hex(),
    lighter: chroma(color).set('hsl.l', 0.95).hex(),
    lightest: chroma(color).set('hsl.l', 0.99).hex(),
    opacity50: chroma(color).alpha(0.5), // TODO setup overwriting on milli
    dark: chroma(color).darken().hex(),
    darker: chroma(color).darken(2).hex(),
  };
};

const createStyleSheet = (content) => {
  const style = document.createElement('style');
  // eslint-disable-next-line unicorn/prefer-node-append
  style.appendChild(document.createTextNode(content));
  style.type = 'text/css';
  return style;
};

export const createStyleSheetContent = (brandColor, overwriteTemplate) => {
  const colorMap = manipulate(brandColor);

  return (overwriteTemplate || template)
    .replace(/__LIGHT_BRAND_COLOR__/g, colorMap.light)
    .replace(/__LIGHTER_BRAND_COLOR__/g, colorMap.lighter)
    .replace(/__LIGHTEST_BRAND_COLOR__/g, colorMap.lightest)
    .replace(/__50_PERCENT_BRAND_COLOR__/g, colorMap.opacity50)
    .replace(/__BRAND_COLOR__/g, brandColor)
    .replace(/__DARK_BRAND_COLOR__/g, colorMap.dark)
    .replace(/__DARKER_BRAND_COLOR__/g, colorMap.darker);
};

export function changeTitle(newTitle) {
  if (newTitle) {
    document.title = newTitle;
  }
}

export function setupBrandColorStylesheet(
  brandColor = DEFAULT_BRAND_COLOR,
  overwriteStylesheet,
) {
  // eslint-disable-next-line unicorn/prefer-query-selector
  const existingStyleSheet = document.getElementById(BRAND_COLOR_STYLESHEET_ID);
  const content = overwriteStylesheet
    ? overwriteStylesheet(brandColor)
    : createStyleSheetContent(brandColor);
  if (existingStyleSheet) {
    existingStyleSheet.innerHTML = content;
  } else {
    const styleSheet = createStyleSheet(content);
    styleSheet.id = BRAND_COLOR_STYLESHEET_ID;
    // eslint-disable-next-line unicorn/prefer-query-selector
    const head = document.head || document.getElementsByTagName('head')[0];
    // eslint-disable-next-line unicorn/prefer-node-append
    head.appendChild(styleSheet);
  }
}

export const setSVGasBrandFavicon = async (faviconConfig) => {
  try {
    const {
      faviconElem,
      faviconSVG: svg,
      fallbackFaviconUrl,
      brandColor = DEFAULT_BRAND_COLOR,
      attrToReplace,
      colorToReplace,
    } = faviconConfig;

    if (isIE && fallbackFaviconUrl) {
      faviconElem.href = fallbackFaviconUrl;
      return;
    }

    const xml = svg.replace(
      `${attrToReplace}="${colorToReplace}"`,
      `${attrToReplace}="${brandColor}"`,
    );
    const faviconSize = 16;

    const canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;

    const context = canvas.getdescribe('2d');
    const vector = await Canvg.fromString(context, xml);
    await vector.render();

    faviconElem.href = canvas.toDataURL();
  } catch (error) {
    console.warn('Failed to load the BYOB favicon.', error);
  }
};

export const setupBrandFavicon = (faviconConfig) => {
  if (!faviconConfig) {
    return console.warn('favicon config is missing!');
  }

  const { faviconSVG } = faviconConfig;

  if (faviconSVG.includes('<svg') && faviconSVG.trim().endsWith('</svg>')) {
    setSVGasBrandFavicon(faviconConfig);
  } else {
    const request = new XMLHttpRequest();
    request.open('GET', faviconSVG);
    request.setRequestHeader('Content-Type', 'image/svg+xml');
    request.addEventListener('load', (event) => {
      const response = event.target.responseText;
      setSVGasBrandFavicon({ ...faviconConfig, faviconSVG: response });
    });
    request.send();
  }
};
