import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Tooltip from '../Tooltip/Tooltip';
import styles from './RadioButton.css';
import buttonStyles from '../Button/Button.css';

const RadioButton = (props, context) => {
  const handleOnClick = (e) => {
    const { onClick, action, value } = props;
    // A small caveat here is that these clientX and clientY values are the same for when the user presses "spacebar"
    // which actually should trigger the focus to move to the next question. Any ideas on how to differentiate between
    // a focus and a spacebar event here (given it's a click event) are welcome :) - Jaryd
    if (e.type === 'click' && e.clientX !== 0 && e.clientY !== 0) {
      onClick && onClick();
    }
    action(value);
  };

  const radioLabelStyle = () => {
    const { selected, isMouseover } = props;
    return classNames.bind(styles)('radioLabel', {
      selectedRadioLabel: selected,
      mouseoverRadioLabel: !selected && isMouseover,
    });
  };

  const buttonStyle = () => {
    const { selected, disabled, className } = props;
    const { inactive } = context;
    return classNames.bind(buttonStyles, className)('base', 'inputButton', {
      active: !selected && !inactive,
      selectedActiveInputButton: selected && !inactive,
      selectedInactiveInputButton: selected && inactive,
      selected,
      disabled,
      inactive,
    });
  };

  const {
    selected,
    name,
    id,
    disabled,
    onFocus,
    label,
    setFocusRef,
    isFilterStyle,
    tooltip,
    theme,
  } = props;
  const inputStyle = classNames.bind(
    styles,
    'root',
  )({
    button: !isFilterStyle,
    radionButton: isFilterStyle,
  });
  const tooltipOutline = theme === 'dark';

  return (
    <div>
      <input
        id={id}
        ref={setFocusRef}
        className={inputStyle}
        type='radio'
        name={name}
        selected={selected}
        checked={selected}
        disabled={disabled}
        onClick={handleOnClick}
        onFocus={onFocus}
        onChange={() => undefined}
      />
      <label
        htmlFor={id}
        className={isFilterStyle ? radioLabelStyle() : buttonStyle()}
      >
        {label}
      </label>
      {tooltip && (
        <Tooltip
          id={id}
          outline={tooltipOutline}
          tooltip={tooltip}
          dataPlace='bottom'
          dataOffset='bottom'
        />
      )}
    </div>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  setFocusRef: PropTypes.func,
  isFilterStyle: PropTypes.bool,
  isMouseover: PropTypes.bool,
  tooltip: PropTypes.string,
  theme: PropTypes.string,
};

RadioButton.defaultProps = {
  disabled: false,
  selected: false,
};

RadioButton.contextTypes = {
  inactive: PropTypes.bool,
};

export default RadioButton;
