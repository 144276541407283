import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './NotificationBanner.css';

const NotificationBanner = ({
  message,
  theme = 'warning',
  iconName = 'mi-icon-warning-banner',
  secondaryIcon = '',
  align = 'left',
  className,
}) => (
  <div className={classNames(styles.root, styles[theme], className)}>
    <div className={classNames(styles.centerWrapper, styles[align])}>
      <i className={classNames(styles.primaryIcon, iconName)}></i>
      <p>{message}</p>
      {secondaryIcon !== '' && (
        <i className={classNames(styles.secondaryIcon, secondaryIcon)}></i>
      )}
    </div>
  </div>
);

NotificationBanner.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  theme: PropTypes.string,
  iconName: PropTypes.string,
  secondaryIcon: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string,
};

export default NotificationBanner;
