import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpritesheetAnimator from './SpritesheetAnimator';
import Button from '../Button/Button';
import styles from './AnimatedButton.css';

class AnimatedButton extends Component {
  static displayName = 'AnimatedButton';
  constructor(props) {
    super(props);
    this.state = {
      animation: 'default',
    };
  }

  render() {
    const { id, onClick, label, url, spriteConfig, theme } = this.props;
    const animator = (
      <SpritesheetAnimator
        {...spriteConfig}
        key={id}
        currentAnimation={this.state.animation}
        className={styles.icon}
      />
    );

    const onMouseEnter = () => {
      this.setState({ animation: 'hover' });
    };
    const onMouseLeave = () => {
      this.setState({ animation: 'afterHover' });
    };

    return (
      <Button
        id={id}
        url={url}
        onClick={onClick}
        theme={theme}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {animator}
        <div className={styles.label}>{label}</div>
      </Button>
    );
  }
}

AnimatedButton.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  spriteConfig: PropTypes.object,
  theme: PropTypes.string,
};

export default AnimatedButton;
