import * as SharedBrandHelper from 'shared/shared-component/lib/brandHelpers';
import faviconLM from 'assets/favicon-lm.svg';
import faviconBYOB from 'assets/favicon-byob.svg';
import fallbackFaviconUrl from 'assets/favicon-byob-fallback.png';
import { LM_BRAND_COLOR, BYOB_REPLACE_COLOR } from 'constants/colours';

export const setupBrandFaviconForMilli = (byobBrandColor) => {
  const favicon = byobBrandColor ? faviconBYOB : faviconLM;
  const colorToReplace = byobBrandColor ? BYOB_REPLACE_COLOR : LM_BRAND_COLOR;
  SharedBrandHelper.setupBrandFavicon({
    faviconElem: document.querySelector('#favicon'),
    faviconSVG: favicon,
    fallbackFaviconUrl,
    brandColor: byobBrandColor || LM_BRAND_COLOR,
    attrToReplace: 'fill',
    colorToReplace,
  });
};

export const setupBYOBDefaults = () => {
  SharedBrandHelper.setupBrandColorStylesheet(BYOB_REPLACE_COLOR);
  setupBrandFaviconForMilli(BYOB_REPLACE_COLOR);
};

export * from 'shared/shared-component/lib/brandHelpers';
