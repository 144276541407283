import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';
import styles from './Spinner.css';

const Spinner = ({
  loading,
  children,
  fillParent,
  content,
  icon,
  className,
}) => {
  const rootClass = classNames.bind(styles, [className])({
    root: true,
    [styles.loading]: loading,
    [styles.fillRoot]: fillParent,
  });

  return (
    <div className={rootClass}>
      <div className={styles.spinnerAndLayer}>
        <div
          className={classNames(styles.layer, {
            [styles.fillLayer]: fillParent,
          })}
        />
        {loading && (
          <div
            id='Spinner'
            className={classNames(styles.spin, {
              [styles.fillLayer]: fillParent,
            })}
          >
            <div
              className={styles.rotation}
              style={{ width: '3rem', height: '3rem' }}
            >
              <SVGInline svg={icon || 'svg icon here'} />
            </div>
            {content}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

Spinner.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  fillParent: PropTypes.bool,
  content: PropTypes.node,
  icon: PropTypes.string,
  className: PropTypes.string,
};
export default Spinner;
