/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SharedView from 'shared/shared-component/components/View';

import { arrayOfStringsObjectsNodes } from 'types/customPropTypes';

import Header from 'components/Header/Header';
import ComplianceFooter from 'components/ComplianceFooter/ComplianceFooter';

import locale from 'config/locale';

import { BUSINESS_UNIT_LM_CORPORATE } from 'shared/constants/myCRMTypes/advisors';

import styles from './View.css';
import HelpFooter from 'components/HelpFooter/HelpFooter';

const renderHeader =
  ({
    theme,
    inverseHeader,
    inverse,
    showNavigation,
    countryCode,
    advisor,
    advisorOrg,
    showLogin,
    showRightContent,
    goal,
    showCallSection,
    showMenu,
    showHeaderLogoOnly,
    customClientName,
  }) =>
  () => {
    const hasAdvisorInfo =
      advisor && (advisor.preferredName || advisor.imgUrl || advisor.mobile);
    const lmCorporateAdvisor =
      hasAdvisorInfo && advisor.businessUnitId === BUSINESS_UNIT_LM_CORPORATE.id
        ? {
            preferredName: 'Loan Market',
            mobile: locale.data.phoneNumber,
            phoneNumberFormatted: locale.data.phoneNumberFormatted,
            title: ' ',
          }
        : null;
    const _props = customClientName
      ? {
          advisor: {},
          // TODO Make <SharedHeader support customName />
          advisorOrg: {
            tradingName: customClientName,
            title: ' ',
            brandLogoUrl: advisorOrg.brandLogoUrl,
            businessName: advisorOrg.businessName,
            isOwnBrand: advisorOrg.isOwnBrand,
          },
          showLogin: false,
          showRightContent: true,
          showCallSection: true,
        }
      : {
          advisor: lmCorporateAdvisor || advisor,
          advisorOrg: advisorOrg,
          showLogin: showLogin,
          showRightContent: showRightContent,
          showCallSection: showCallSection,
          goal: goal,
          showMenu: showMenu,
        };

    return (
      <Header
        theme={theme}
        inverse={inverseHeader || inverse}
        showNavigation={showNavigation}
        countryCode={countryCode}
        showHeaderLogoOnly={showHeaderLogoOnly}
        {..._props}
      />
    );
  };

const renderFooter =
  ({
    advisorOrg,
    inverse,
    footerDisclaimer,
    hideStandardDisclaimer,
    showHelpFooter,
  }) =>
  () => {
    return (
      <>
        {showHelpFooter ? (
          <HelpFooter />
        ) : (
          <ComplianceFooter
            brand={advisorOrg.isOwnBrand ? advisorOrg.businessName : null}
            inverse={inverse}
            footerDisclaimer={footerDisclaimer}
            hideStandardDisclaimer={hideStandardDisclaimer}
          />
        )}
      </>
    );
  };

const View = ({
  countryCode,
  theme,
  showHeader,
  showRightContent,
  showFooter,
  showHeaderLogoOnly,
  children,
  inverse,
  inverseHeader,
  footerDisclaimer,
  hideStandardDisclaimer,
  showNavigation,
  hide,
  advisor,
  advisorOrg,
  showLogin,
  goal,
  showCallSection,
  showMenu,
  customClientName,
  showHelpFooter,
}) => {
  return (
    <SharedView
      theme={styles[theme]}
      inverse={inverse}
      hide={hide}
      renderHeader={
        showHeader
          ? renderHeader({
              theme,
              inverseHeader,
              inverse,
              showNavigation,
              countryCode,
              advisor,
              advisorOrg,
              showLogin,
              showRightContent,
              showCallSection,
              showHeaderLogoOnly,
              goal,
              showMenu,
              customClientName,
            })
          : undefined
      }
      renderFooter={
        showFooter
          ? renderFooter({
              advisor,
              advisorOrg,
              inverse,
              footerDisclaimer,
              hideStandardDisclaimer,
              showHelpFooter,
            })
          : undefined
      }
    >
      {children}
    </SharedView>
  );
};

View.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeaderLogoOnly: PropTypes.bool,
  children: PropTypes.node,
  advisorOrg: PropTypes.object,
  footerDisclaimer: arrayOfStringsObjectsNodes,
  inverse: PropTypes.bool,
  hideStandardDisclaimer: PropTypes.bool,
  advisor: PropTypes.object,
  countryCode: PropTypes.string,
  theme: PropTypes.string,
  inverseHeader: PropTypes.bool,
  showNavigation: PropTypes.bool,
  hide: PropTypes.bool,
  showLogin: PropTypes.bool,
  showRightContent: PropTypes.bool,
  goal: PropTypes.bool,
  customClientName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  isCustomerCare: PropTypes.bool,
  showCallSection: PropTypes.bool,
  showMenu: PropTypes.bool,
  showHelpFooter: PropTypes.bool,
};

View.defaultProps = {
  showHeader: true,
  showFooter: true,
  children: null,
  countryCode: 'AU',
  showNavigation: true,
  inverse: false,
  inverseHeader: false,
  hideStandardDisclaimer: false,
  showLogin: true,
  goal: false,
  showHelpFooter: false,
};

const mapStateToProps = (state) => ({
  advisor: state.advisor,
  advisorOrg: state.advisorOrg,
  countryCode: state.application.working.countryCode,
  customClientName: state.privacyPolicy.clientName,
});

export default connect(mapStateToProps, null)(View);
