import React, { Component } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import { formatMobile } from '../../lib/utils';
import styles from './ApplyBrokerCard.css';

const applyPhoneTrackingId = 'apply-broker-phone-tracking';

class ApplyBrokerCard extends Component {
  static displayName = 'ApplyBrokerCard';

  renderPhoneSection = (unformattedPhone, formattedPhone) => (
    <a
      href={`tel:${unformattedPhone}`}
      id={applyPhoneTrackingId}
      className='brandColor__link'
      onClick={this.props.clickToCall}
    >
      <i className='mi-phone' />
      <span id={applyPhoneTrackingId}>{formattedPhone}</span>
    </a>
  );

  renderLogo() {
    const {
      advisorOrg: { brandLogoUrl, isOwnBrand },
      platform,
    } = this.props;

    return isOwnBrand ? (
      <img className={styles.brandLogoByob} src={brandLogoUrl} />
    ) : (
      <span className={styles.brandLogo}>{platform.renderLogo()}</span>
    );
  }

  renderBrokerImage() {
    const {
      advisor: { halfBodyImgUrl },
      platform,
    } = this.props;
    return halfBodyImgUrl ? (
      <img className={styles.brokerImage} src={halfBodyImgUrl} />
    ) : (
      <SVGInline
        className={styles.brokerIcon}
        svg={platform.defaultBrokerImage}
      />
    );
  }

  render() {
    const {
      advisor: { name, preferredName, lastName, mobile, displayTitle },
      advisorOrg: { isOwnBrand },
    } = this.props;
    const advisorName = preferredName ? `${preferredName} ${lastName}` : name;
    return (
      <div
        className={classNames.bind(styles)('root', {
          byob: isOwnBrand,
        })}
      >
        <div>
          {this.renderLogo()}
          <span className={styles.advisorName}>{advisorName}</span>
          {displayTitle && (
            <span className={styles.displayTitle}>{displayTitle}</span>
          )}
          {mobile && (
            <span className={styles.telNumber}>
              {this.renderPhoneSection(mobile, formatMobile(mobile))}
            </span>
          )}
        </div>
        {this.renderBrokerImage()}
      </div>
    );
  }
}

ApplyBrokerCard.propTypes = {
  advisor: PropTypes.object,
  advisorOrg: PropTypes.object,
  platform: PropTypes.object,
  clickToCall: PropTypes.func,
};

ApplyBrokerCard.defaultProps = {
  advisor: {},
  advisorOrg: {},
};

export default ApplyBrokerCard;
