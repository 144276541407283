import React, { Component } from 'react';
import PropTypes from 'prop-types';
import raf from 'raf';

const animate = (ComposedComponent) => {
  class Animator extends Component {
    static propTypes = {
      sprite: PropTypes.string,
      currentAnimation: PropTypes.string,
      animations: PropTypes.object.isRequired,
      fps: PropTypes.number,
    };

    constructor(props) {
      super(props);

      this.state = {
        spriteWidth: 0,
        spriteHeight: 0,
        currentFrame: 0,
        startFrame: 0,
        isLoaded: false,
        hasError: false,
      };

      this.interval = 1000 / props.fps;
      this.animationFunc = this.animate.bind(this);
    }

    componentDidMount() {
      this.loadSprite();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { currentAnimation, animations } = nextProps;

      if (currentAnimation !== this.props.currentAnimation) {
        const anim = animations[currentAnimation];

        const newState = {
          ...anim,
          currentFrame: anim.startFrame,
        };

        this.animationId !== null && raf.cancel(this.animationId);

        this.setState(newState);
        this.animationId = raf(this.animationFunc);
      }
    }

    componentWillUnmount() {
      this.animationId !== null && raf.cancel(this.animationId);
    }

    loadSprite() {
      const img = new Image();
      img.src = this.props.sprite;
      img.addEventListener('load', () => {
        const { currentAnimation, animations } = this.props;
        const anim = animations[currentAnimation];

        this.setState({
          ...anim,
          isLoaded: true,
          currentFrame: anim.startFrame,
          spriteWidth: img.width,
          spriteHeight: img.height,
        });

        this.animationId = raf(this.animationFunc);
      });
      img.addEventListener('error', () => {
        this.setState({ hasErrored: true });
      });
    }

    animate(time) {
      if (!this.prevTime) {
        this.prevTime = time;
      }

      const delta = time - this.prevTime;

      if (delta < this.interval) {
        this.animationId = raf(this.animationFunc);
        return;
      }

      const { endFrame, currentFrame, startFrame, stopLastFrame } = this.state;
      let nextFrame = currentFrame + 1;

      if (nextFrame >= endFrame) {
        if (stopLastFrame) {
          return;
        }
        nextFrame = startFrame;
      }

      this.prevTime = time - (delta % this.interval);
      this.setState({ currentFrame: nextFrame });

      this.animationId = raf(this.animationFunc);
    }

    render() {
      const { isLoaded, currentFrame } = this.state;

      return (
        <ComposedComponent
          frame={currentFrame}
          isLoaded={isLoaded}
          spriteWidth={this.state.spriteWidth}
          spriteHeight={this.state.spriteHeight}
          {...this.props}
        />
      );
    }
  }

  return Animator;
};

export default animate;
