import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './View.css';

const View = ({
  theme,
  children,
  inverse,
  hide,
  renderFooter,
  renderHeader,
}) => {
  return (
    <main
      className={classNames.bind(styles)('root', {
        [theme]: !!theme,
        inverse,
        hide,
      })}
    >
      {renderHeader && renderHeader()}
      {children}
      {renderFooter && renderFooter()}
    </main>
  );
};
View.displayName = 'View';
View.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node,
  inverse: PropTypes.bool,
  hide: PropTypes.bool,
  renderFooter: PropTypes.func,
  renderHeader: PropTypes.func,
};

View.defaultProps = {
  children: null,
  inverse: false,
};

export default View;
