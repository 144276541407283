import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import {
  formatMobile,
  formatPhoneNumber,
  isEmptyObject,
  format1800Number,
} from '../../lib/utils';

import ClickOutsideClose from '../ClickOutsideClose/ClickOutsideClose';
import styles from './Header.css';

export class Header extends Component {
  static displayName = 'Header';
  static setClassNameByPath = (pagePath) => {
    return window.location.hash.includes(pagePath) ? styles.activeLink : '';
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  linkClassName() {
    const { inverse } = this.props;
    return inverse ? null : 'brandColor__link';
  }

  renderLogo() {
    const {
      inverse,
      advisorOrg: { businessName, brandLogoUrl, isOwnBrand },
      platform,
    } = this.props;

    return (
      <div className={styles.logoWrapper}>
        <meta
          content={isOwnBrand ? businessName : platform.name}
          itemProp='brand'
        />
        {isOwnBrand ? (
          <img src={brandLogoUrl} className={styles.brandLogo} />
        ) : (
          platform.renderLogo(inverse)
        )}
      </div>
    );
  }

  renderCustomNavigation = (items) => {
    return items.map((menuItem, i) => (
      <li
        onClick={menuItem.onClick}
        key={`menu-item-${i}`}
        className={Header.setClassNameByPath(menuItem.applicationId)}
      >
        <a className='brandColor__link'>
          <i className={menuItem.icon} />
          <div>
            {menuItem.title}
            <span>{menuItem.description}</span>
          </div>
        </a>
      </li>
    ));
  };

  renderNavigationBar() {
    const {
      messages,
      platform,
      advisorOrg: { isOwnBrand },
      customNavigation: { items, theme },
      hideLogoutButton,
    } = this.props;

    return (
      <ClickOutsideClose
        ref={(c) => {
          this.c = c;
        }}
        theme={theme || 'navigationMenu'}
      >
        <ul>
          {!!items && items.length ? (
            this.renderCustomNavigation(items)
          ) : (
            <li className={Header.setClassNameByPath(platform.dashboardPath)}>
              <Link to={platform.dashboardPath} className='brandColor__link'>
                <i className='sl-custom-house-2' />
                {messages.myDashboard}
              </Link>
            </li>
          )}
          {!hideLogoutButton && (
            <li onClick={() => platform.logoutAction(isOwnBrand)}>
              <a className='brandColor__link'>
                <i className='sl-custom-log-out-1' />
                {messages.logOutText}
              </a>
            </li>
          )}
        </ul>
      </ClickOutsideClose>
    );
  }

  renderLoginButton() {
    const { messages, platform } = this.props;
    return (
      <div className={styles.loginSection} key='login'>
        <Link to={platform.loginPath} className={this.linkClassName()}>
          {messages.logInText}
        </Link>
      </div>
    );
  }

  renderLogoutButton() {
    const {
      messages,
      platform,
      advisorOrg: { isOwnBrand },
    } = this.props;
    return (
      <div className={styles.logoutSection} key='logout'>
        <Link
          to={platform.loginPath}
          className={this.linkClassName()}
          onClick={() => platform.logoutAction(isOwnBrand)}
        >
          {messages.logOutText}
        </Link>
      </div>
    );
  }

  renderMenu() {
    const { showNavigation } = this.props;
    return (
      <div
        className={styles.menuSection}
        onMouseDown={() => this.c && this.c.showContent()}
      >
        <div className={styles.advisorMenuSection}>
          <i className='mi-menu' />
          <span>menu</span>
        </div>
        {showNavigation && this.renderNavigationBar()}
      </div>
    );
  }

  shouldRenderAdvisor = () => {
    const { advisor } = this.props;
    const hasAdvisorInfo =
      advisor &&
      (advisor.preferredName ||
        advisor.imgUrl ||
        advisor.mobile ||
        advisor.businessPhone);
    return !!hasAdvisorInfo;
  };

  renderCallSection() {
    const { advisorOrg, showCallSection } = this.props;

    if (!showCallSection) {
      return;
    }
    if (this.shouldRenderAdvisor()) {
      return this.renderAdvisor();
    }
    if (advisorOrg && advisorOrg.isOwnBrand) {
      return this.renderBranded();
    }
    if (!isEmptyObject(advisorOrg)) {
      return this.renderAdvisorOrg();
    }
    return this.renderCallLoanMarket();
  }

  handleAdvisorPhoneMobileNumberFormat(
    advisorPhoneNumber,
    advisorPhoneNumberFormatted,
    officePhone,
    countryCode,
  ) {
    if (advisorPhoneNumber) {
      const firstTwo = advisorPhoneNumber.slice(0, 2);
      if (firstTwo === '18') {
        return format1800Number(advisorPhoneNumber);
      }
      if (advisorPhoneNumberFormatted) {
        return advisorPhoneNumberFormatted;
      } else {
        return formatMobile(advisorPhoneNumber);
      }
    }
    return formatPhoneNumber(officePhone, countryCode);
  }

  renderAdvisor() {
    const {
      showAdvisorImageOnly,
      countryCode,
      advisor: {
        imgUrl,
        preferredName,
        lastName,
        mobile,
        businessPhone,
        phoneNumberFormatted: advisorPhoneNumberFormatted,
        title,
      },
      advisorOrg: { officePhone },
    } = this.props;
    const advisorPhoneNumber = mobile || businessPhone;

    const phoneNumber = advisorPhoneNumber || officePhone;
    const phoneNumberFormatted = this.handleAdvisorPhoneMobileNumberFormat(
      advisorPhoneNumber,
      advisorPhoneNumberFormatted,
      officePhone,
      countryCode,
    );

    return (
      <div className={styles.advisorSection}>
        {imgUrl && (
          <img
            className={classNames(styles.advisorImg, {
              [styles.smallAdvisorImg]: showAdvisorImageOnly,
            })}
            src={imgUrl}
          />
        )}
        {!showAdvisorImageOnly && (
          <div className={styles.advisorNameAndMobile}>
            <h5>{`${preferredName} ${lastName || ''}`.trim()}</h5>
            <span>
              {title ||
                (countryCode === 'NZ' ? 'Mortgage adviser' : 'Mortgage broker')}
            </span>
            {this.renderPhoneSection(phoneNumber, phoneNumberFormatted)}
          </div>
        )}
      </div>
    );
  }

  renderAdvisorOrg() {
    const {
      advisorOrg: { tradingName, officePhone, title },
      countryCode,
    } = this.props;
    return (
      <div className={styles.advisorSection}>
        <div className={styles.advisorNameAndMobile}>
          <h5>{tradingName}</h5>
          <span>
            {title ||
              (countryCode === 'NZ' ? 'Mortgage advisers' : 'Mortgage brokers')}
          </span>
          {this.renderPhoneSection(
            officePhone,
            formatPhoneNumber(officePhone, countryCode),
          )}
        </div>
      </div>
    );
  }

  renderBranded() {
    const {
      advisorOrg: { officePhone, tradingName },
      countryCode,
    } = this.props;
    return (
      <div className={styles.advisorNameAndMobile}>
        <h5>{tradingName}</h5>
        <div className={styles.call}>
          {this.renderPhoneSection(
            officePhone,
            formatPhoneNumber(officePhone, countryCode),
          )}
        </div>
      </div>
    );
  }

  renderCallLoanMarket() {
    const { messages, platform } = this.props;
    const localisedPhoneNumber = platform.phoneNumberFormatted;
    return (
      <div className={styles.call}>
        {this.renderPhoneSection(
          platform.phoneNumber,
          `${messages.callText} ${localisedPhoneNumber}`,
          true,
        )}
      </div>
    );
  }

  renderPhoneSection(unformattedPhone, formattedPhone, shouldTrack = false) {
    if (!unformattedPhone) {
      return null;
    }
    const trackingId = shouldTrack ? 'phone-tracking' : '';
    return (
      <div className={styles.phoneSection}>
        <a
          href={`tel:${unformattedPhone}`}
          id={trackingId}
          className={this.linkClassName()}
        >
          <i className='mi-phone' />
          <span id={trackingId}>{formattedPhone}</span>
        </a>
      </div>
    );
  }

  render() {
    const {
      showNavigation,
      showMenu,
      inverse,
      theme,
      isLoggedIn,
      showLogin,
      showRightContent,
    } = this.props;
    const className = classNames.bind(styles)('root', {
      showNavigation,
      inverse,
      standard: !inverse,
      [theme]: !!theme,
    });

    const headerNavigation = showMenu
      ? this.renderMenu()
      : this.renderLogoutButton();
    return (
      <header className={className}>
        <div className={styles.container}>
          {this.renderLogo()}
          {showRightContent && (
            <div className={styles.rightWrapper}>
              {this.renderCallSection()}
              {isLoggedIn
                ? headerNavigation
                : showLogin && this.renderLoginButton()}
            </div>
          )}
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  advisor: PropTypes.object,
  advisorOrg: PropTypes.object,
  showNavigation: PropTypes.bool,
  showMenu: PropTypes.bool,
  showRightContent: PropTypes.bool,
  inverse: PropTypes.bool,
  theme: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  platform: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool,
  showCallSection: PropTypes.bool,
  customNavigation: PropTypes.object,
  showAdvisorImageOnly: PropTypes.bool,
  hideLogoutButton: PropTypes.bool,
};

Header.defaultProps = {
  advisor: {},
  advisorOrg: {},
  inverse: false,
  showLogin: true,
  showMenu: true,
  showRightContent: true,
  showCallSection: true,
  customNavigation: {},
};

export default Header;
