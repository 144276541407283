/* eslint-disable unicorn/prefer-string-slice */
import rangeRight from 'lodash/rangeRight';
import range from 'lodash/range';

export function unformatPhoneNumber(mobile) {
  if (!mobile) {
    return mobile;
  }
  return mobile.replace(/[^\d+]/g, '');
}

export function removeCountryCode(mobile) {
  if (!mobile) {
    return mobile;
  }
  return mobile.replace(/^\+?(\s+)?6(\s+)?(4|1)/g, '').trim() || '';
}

export function sensibleSpacing(mobile) {
  const firstTwo = mobile.substr(0, 2);
  const firstThree = mobile.substr(0, 3);
  const isNZ = firstTwo === '02';

  const prefixLength =
    (isNZ && firstThree === '020') || firstTwo === '04' ? 4 : 3;

  const prefixSet = mobile.substr(0, prefixLength);
  const numProper = mobile.substr(prefixLength);

  const splitIndex = Math.floor(numProper.length / 2);

  // eslint-disable-next-line unicorn/prefer-string-slice
  const firstSet = splitIndex ? ` ${numProper.substring(0, splitIndex)}` : '';
  const lastSet = ` ${numProper.substring(splitIndex)}`;

  return `${prefixSet}${firstSet}${lastSet}`;
}

/*
formatMobile
  @params mobile
  format mobile format 
  example: 0411864669
  expected: 0411 864 669 
*/
export function formatMobile(mobile) {
  if (!mobile) {
    return mobile;
  }
  const sanitized = unformatPhoneNumber(mobile);
  const length = sanitized.length;
  if (length <= 3) {
    return sanitized;
  }

  const noCountryCode = removeCountryCode(sanitized);
  const withFirstZero = `${
    noCountryCode[0] === '0' ? '' : '0'
  }${noCountryCode}`;

  return sensibleSpacing(withFirstZero);
}

/*
format1800Number
  @params mobile
  format 1800 mobile format 
  example: 1800666777
  expected: 1800 666 777
*/
export function format1800Number(phoneNumber) {
  const first = phoneNumber.substr(0, 4);
  const second = phoneNumber.substr(4, 3);
  const third = phoneNumber.substr(7, 3);

  return `${first} ${second} ${third}`;
}

/*
format61Number
  @params mobile
  format 61 mobile format 
  example: 61412456236
  expected: 61 412 456 236 
*/

export function format61Number(number) {
  const first = number.substr(0, 2);
  const second = number.substr(2, 3);
  const third = number.substr(5, 3);
  const forth = number.substr(8, 3);

  return `${first} ${second} ${third} ${forth}`;
}

export function formatLandline(landline, isNZ) {
  if (landline[0] !== '0') {
    return landline;
  }

  const sanitized = unformatPhoneNumber(landline);
  const length = sanitized.length;
  const areaCodeLength = 2;
  const midSetLength = isNZ ? 3 : 4;
  const lastSetStartingPos = areaCodeLength + midSetLength;

  if (length < areaCodeLength) {
    return sanitized;
  }

  // eslint-disable-next-line prettier/prettier
  return `${sanitized.substr(0, areaCodeLength)} ${sanitized.substr(areaCodeLength, midSetLength)} ${sanitized.substr(lastSetStartingPos)}`;
}

/*
AU
  mobile: 10 digits. (04xx xxx xxx, 1800 xxx xxx)
  landline: 10 digits. (0x xxxx xxxx)
NZ
  mobile: usually 10 digits but can be 9 - 11 digits. (02x xxx xxx, 02x xxx xxxx, 02x xxxx xxxx, 020x xx xxx, 020x xxx xxx, 020x xxx xxxx) - always start with 02
  landline: 9 digits. begins with 0, follwed by area code(3, 4, 6, 7, or 9) (e.g. 09 xxx xxxx)
*/

export function formatPhoneNumber(phoneNumber, countryCode) {
  if (phoneNumber === null || typeof phoneNumber === 'undefined') {
    return '';
  }
  // eslint-disable-next-line unicorn/better-regex
  if (phoneNumber.match(/[^\d]/)) {
    return phoneNumber;
  }

  const firstTwo = phoneNumber.substr(0, 2);

  if (firstTwo === '18') {
    return format1800Number(phoneNumber);
  }

  if (firstTwo === '61') {
    return format61Number(phoneNumber);
  }

  if (countryCode === 'NZ') {
    return firstTwo === '02'
      ? formatMobile(phoneNumber)
      : formatLandline(phoneNumber, true);
  }

  return firstTwo === '04'
    ? formatMobile(phoneNumber)
    : formatLandline(phoneNumber, false);
}

export function isEmptyObject(object) {
  return !!(
    object &&
    // eslint-disable-next-line unicorn/no-array-instanceof
    !(object instanceof Array) &&
    object instanceof Object &&
    Object.keys(object).length === 0
  );
}

export function generateUniqueID(string, id) {
  return `${string}__${id}`;
}

export function arrayToTruthyObj(array) {
  if (!array) {
    return {};
  }
  return array.reduce((result, a) => {
    result[a] = true;
    return result;
  }, {});
}

export function truthyObjToArray(obj) {
  if (!obj) {
    return [];
  }
  return Object.keys(obj).reduce((accum, key) => {
    return obj[key] ? accum.concat(key) : accum;
  }, []);
}

export function makeArrayFromRange(min, max, values, isDesc) {
  return (() => (isDesc ? rangeRight : range))()(min, max + 1).map((a) => ({
    value: a,
    name: values ? values[a] : a,
  }));
}
