import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Input.css';

/**
 * @render react
 * @name Input component
 * @description Input.
 * @example
 * <Input
 *    type="email"
 *    displayOnly={true}
 * />
 */

class Input extends Component {
  static displayName = 'Input';
  static propTypes = {
    id: PropTypes.string,
    action: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    pattern: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
    maxLength: PropTypes.number,
    min: PropTypes.string,
    max: PropTypes.string,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    dispatchActionOnBlur: PropTypes.bool,
    setFocusRef: PropTypes.func,
    displayOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    format: PropTypes.func,
    unformat: PropTypes.func,
    formatOnBlur: PropTypes.func,
    inputMode: PropTypes.string,
  };

  static defaultProps = {
    maxLength: null,
    changeCallbackOnBlur: null,
    value: '',
    onKeyDown: () => {},
    displayOnly: false,
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.format = props.format || ((x) => x);
    this.unformat = props.unformat || ((x) => x);
    this.state = {
      startIdx: 0,
      endIdx: 0,
    };
  }

  handleOnChange = (onChange) => (e) => {
    if (onChange) {
      this.inputValue.value = this.format(onChange(this.props.value));
    } else {
      this.props.action(this.unformat(e.target.value));
    }

    // if there's no custom format, no need to keep track of caret
    if (!this.props.format) {
      return;
    }
    if (this.inputValue.type !== 'email' && this.inputValue.type !== 'number') {
      this.setState({
        startIdx: this.inputValue.selectionStart || 0,
        endIdx: this.inputValue.selectionEnd || 0,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if there's no custom format, no need to keep track of caret.
    // for now let's only keep track on tel input as it's more complicated on other types
    if (
      !this.props.format ||
      !this.inputValue.setSelectionRange ||
      this.props.type !== 'tel'
    ) {
      return;
    }

    const { value } = this.inputValue;
    const { startIdx } = this.state;

    const currentValue = value.toString();
    const previousValue = this.format(prevProps.value).toString();

    const hasNoChanges = currentValue === previousValue;
    if (hasNoChanges) {
      // nothing has changed, should retain selection range
      if (startIdx !== prevState.startIdx) {
        this.inputValue.setSelectionRange(startIdx, startIdx);
      }
      return;
    }

    // fix caret jumping at the end
    const inputHasIncremented = currentValue.length > previousValue.length;

    const prevCharIsSpace = currentValue[startIdx - 1] === ' ';

    // start and end are the same
    const commonIdx = prevCharIsSpace
      ? (inputHasIncremented && startIdx + 1) || startIdx - 1
      : startIdx;

    this.inputValue.setSelectionRange(commonIdx, commonIdx);
  };

  render() {
    const {
      id,
      action,
      value,
      pattern,
      className,
      maxLength,
      placeholder,
      onFocus,
      onBlur,
      onKeyDown,
      onKeyUp,
      onChange,
      type,
      dispatchActionOnBlur,
      setFocusRef,
      min,
      max,
      displayOnly,
      disabled,
      formatOnBlur,
      inputMode,
    } = this.props;
    const { errored, inactive } = this.context;

    const rootStyle = classNames(className, styles.root, {
      [styles.errored]: errored,
      [styles.inactive]: inactive,
      [styles.displayOnly]: displayOnly,
    });

    const sharedProps = {
      type: type || 'text',
      id,
      placeholder,
      maxLength,
      className: rootStyle,
      pattern,
      onFocus,
      onKeyDown,
      onBlur,
      onKeyUp,
      min,
      max,
      disabled,
      ref: (ref) => {
        this.inputValue = ref;
        setFocusRef && setFocusRef(ref);
      },
      inputMode,
    };

    if (dispatchActionOnBlur) {
      sharedProps.onBlur = (e) => {
        action(this.unformat(e.target.value));
      };
      if (onChange) {
        sharedProps.onChange = this.handleOnChange(onChange);
      }
      sharedProps.onKeyPress = (e) =>
        e.key === 'Enter' && action(this.unformat(e.target.value));
      sharedProps.defaultValue = value !== undefined ? this.format(value) : '';
    } else {
      sharedProps.onChange = this.handleOnChange();
      sharedProps.value = value !== undefined ? this.format(value) : '';
    }

    if (formatOnBlur) {
      sharedProps.onBlur = (e) => {
        this.inputValue.value = formatOnBlur(value);
        action(this.unformat(e.target.value));
      };
    }

    return <input {...sharedProps} />;
  }
}

export default Input;
