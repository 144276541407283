import React from 'react';
import PropTypes from 'prop-types';
import SharedChecklist from 'shared/shared-component/components/Checklist';
import checkSVG from 'assets/icons/check.svg';
import circleSVG from 'assets/icons/circle.svg';

import './Checklist.css';

const Checklist = ({
  id,
  items,
  value,
  action,
  simplified,
  inline,
  disabled,
  checkImage,
  circleImage,
  className,
}) => {
  const props = {
    id,
    items,
    value,
    action,
    simplified,
    inline,
    disabled,
    checkImage,
    circleImage,
    className,
  };

  return <SharedChecklist {...props} />;
};

Checklist.propTypes = {
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.object,
  action: PropTypes.func.isRequired,
  simplified: PropTypes.bool,
  inline: PropTypes.bool,
  disabled: PropTypes.bool,
  checkImage: PropTypes.string,
  circleImage: PropTypes.string,
  className: PropTypes.string,
};

Checklist.displayName = 'Checklist';
Checklist.defaultProps = {
  disabled: false,
  checkImage: checkSVG,
  circleImage: circleSVG,
};

export default Checklist;
