import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';
import styles from './Tooltip.css';

class Tooltip extends React.Component {
  tooltipRef = null;

  setTooltipRef = (element) => {
    this.tooltipRef = element;
  };

  render() {
    const { id, tooltip, tooltipIcon, text, dataPlace, outline } = this.props;
    const clickHandler = (e) => {
      ReactTooltip.show(this.tooltipRef);
      e.stopPropagation();
    };
    const tooltipClass = classNames({
      [styles.tooltip]: true,
      [styles.outline]: outline,
    });

    return (
      <div className={tooltipClass}>
        <div
          onClick={clickHandler}
          data-tip={tooltip}
          className={tooltipIcon ? styles.tooltipSVG : styles.tooltipText}
          id={id}
          data-for={`tooltip_${id}`}
          data-place={dataPlace}
          ref={this.setTooltipRef}
        >
          {tooltipIcon ? <SVGInline svg={tooltipIcon} /> : text}
        </div>
        <ReactTooltip
          className={styles.tt}
          effect='solid'
          id={`tooltip_${id}`}
          multiline
        />
      </div>
    );
  }
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  text: PropTypes.string,
  dataPlace: PropTypes.string,
  tooltipIcon: PropTypes.string,
  outline: PropTypes.bool,
};

Tooltip.defaultProps = {
  text: '?',
  dataFor: '',
  dataPlace: 'top',
  outline: false,
};

export default Tooltip;
