import React from 'react';
import PropTypes from 'prop-types';
import styles from './ClickOutsideCloseContent.css';

// This is a required file / component for react-onclickoutside with function hardcoded handleClickOutside
class ClickOutsideCloseContent extends React.Component {
  static displayName = 'ClickOutsideCloseContent';
  static propTypes = {
    theme: PropTypes.string,
    children: PropTypes.node,
    hide: PropTypes.func,
  };

  handleClickOutside() {
    this.props.hide();
  }

  render() {
    return (
      <div className={styles[this.props.theme]}>{this.props.children}</div>
    );
  }
}

export default ClickOutsideCloseContent;
