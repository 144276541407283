import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
import { AsYouType, getExampleNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import locale from 'config/locale';
import SharedInput from 'shared/shared-component/components/Input';
import {
  formatInternationalNumber,
  removeDialCode,
  spaceOnlyFormatting,
} from 'lib/utils/numberUtils';
import examples from 'libphonenumber-js/examples.mobile.json';
import useFlagInput from 'hooks/useFlagInput';

import {
  PREFERRED_COUNTRY_CODES,
  EXCLUDED_COUNTRY_CODES,
} from 'shared/constants/myCRMTypes/countries';

import inputStyles from './Input.css';
import styles from './MobileInputIntl.css';

// eslint-disable-next-line sonarjs/cognitive-complexity
const MobileInputIntl = (props) => {
  const defaultCountryCode = locale.data.countryCode;
  const {
    onChange,
    disabled,
    displayOnly,
    value = '',
    countryCode = defaultCountryCode,
    dialCode = '',
    onFocus,
    isMobile,
  } = props;
  const [displayValue, setDisplayValue] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [flagInputRef, flagInputCountryData] = useFlagInput(value);
  const mobileInputRef = useRef();
  const btnClass = disabled ? 'flag-dropdown-disabled' : '';

  const handleInputChange = (phoneNum) => {
    const newVal = phoneNum.startsWith('+')
      ? phoneNum
      : formatInternationalNumber(phoneNum, countryCode);
    onChange({
      number: newVal,
      countryCode: countryCode.toUpperCase(),
      dialCode,
    });
  };

  const handleFlagChange = (phoneNum, countryData) => {
    if (countryData) {
      onChange({
        number: '',
        dialCode: countryData.dialCode,
        countryCode: countryData.countryCode.toUpperCase(),
      });
      setDisplayValue('');
    }
    if (mobileInputRef.current && mobileInputRef.current.inputValue) {
      mobileInputRef.current.inputValue.focus();
    }
  };

  useEffect(() => {
    if (countryCode && value) {
      const phoneNumber = new AsYouType(countryCode.toUpperCase()).input(value);
      if (phoneNumber) {
        const newDisplayValue = removeDialCode(
          phoneNumber,
          countryCode,
          isMobile,
        );
        setDisplayValue(newDisplayValue);
      }
      if (!value.startsWith('+')) {
        onChange({
          number: formatInternationalNumber(value, countryCode),
          countryCode: countryCode.toUpperCase(),
          dialCode,
        });
      }
    }
    if (value === '') {
      setDisplayValue(value);
      const sampleNumber = getExampleNumber(countryCode, examples);
      if (sampleNumber) {
        setPlaceholder(spaceOnlyFormatting(sampleNumber.formatNational()));
      }
    }
  }, [value, countryCode]);

  useEffect(() => {
    const data = flagInputCountryData || {};
    if (data.dialCode && data.dialCode !== dialCode) {
      onChange({
        number: value,
        countryCode: data.countryCode.toUpperCase(),
        dialCode: data.dialCode,
      });
    }
  }, [displayValue, flagInputCountryData]);

  return (
    <>
      <PhoneInput
        defaultCountry={defaultCountryCode.toLowerCase()}
        value={value}
        ref={flagInputRef}
        preferredCountries={PREFERRED_COUNTRY_CODES}
        excludeCountries={EXCLUDED_COUNTRY_CODES}
        onChange={handleFlagChange}
        disabled={disabled || displayOnly}
        buttonClass={btnClass}
        disableAreaCodes
      />
      <div className={styles.inputWrapper}>
        <SharedInput
          id='reactTelInput'
          maxLength={16}
          type='tel'
          action={handleInputChange}
          className={inputStyles.input}
          value={displayValue}
          ref={mobileInputRef}
          displayOnly={displayOnly}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFocus}
        />
      </div>
    </>
  );
};

MobileInputIntl.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  countryCode: PropTypes.string,
  dialCode: PropTypes.string,
  disabled: PropTypes.bool,
  displayOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  isMobile: PropTypes.bool,
};

export default MobileInputIntl;
