import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { generateUniqueID } from '../../lib/utils';

import Button from '../Button';
import AnimatedButton from '../AnimatedButton';

import styles from './ButtonList.css';

const ButtonList = ({
  action,
  animated,
  animationConfig,
  id,
  items,
  theme,
}) => {
  const rootStyle = classNames.bind(styles)('root', theme);
  const buttonListClass = classNames.bind(styles)('item', {
    buttonAnimated: animated,
  });
  const buttonClass = classNames(styles.button, 'alignLeft');
  const onClick = (item) => () => action(item.value);

  const listItem = (item) => {
    return (
      <Fragment>
        <li key={item.value} className={buttonListClass}>
          <Button
            className={buttonClass}
            id={`${id}--${item.value}`}
            icon={item.icon}
            onClick={onClick(item)}
            theme={theme}
            url={item.url}
          >
            {item.label}
          </Button>
        </li>
        {animated && (
          <li
            key={`animated_${item.key}`}
            className={classNames(styles.item, styles.animated)}
          >
            <AnimatedButton
              id={`animated_${id}--${item.key}`}
              label={item.label}
              onClick={onClick(item)}
              spriteConfig={animationConfig[item.sprite]}
              theme={theme}
              url={item.url}
            />
          </li>
        )}
      </Fragment>
    );
  };

  return (
    <ol id={generateUniqueID('ButtonList', id)} className={rootStyle}>
      {items.map((option) => listItem(option))}
    </ol>
  );
};

ButtonList.displayName = 'ButtonList';

ButtonList.defaultProps = {
  animated: false,
  theme: 'inputButton',
};

ButtonList.propTypes = {
  action: PropTypes.func.isRequired,
  animated: PropTypes.bool,
  animationConfig: PropTypes.object,
  id: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  theme: PropTypes.string,
};

export default ButtonList;
