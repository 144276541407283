import React from 'react';
import PropTypes from 'prop-types';

import { formatPhoneNumber } from 'shared/shared-component/lib/utils';

const HelpContactInfo = (props) => {
  const { name, mobile, email, countryCode } = props;

  return (
    <ul data-testid='help.contact.info'>
      {name && (
        <li>
          <i className='sl-custom-user-2' />
          <span>{name}</span>
        </li>
      )}
      {mobile && (
        <li>
          <i className='sl-custom-mobile-phone-1' />
          <span>
            <a href={`tel:${mobile}`} className='brandColor__link'>
              {formatPhoneNumber(mobile, countryCode)}
            </a>
          </span>
        </li>
      )}
      {email && (
        <li>
          <i className='sl-custom-email-2' />
          <span>
            <a href={`mailto:${email}`}>{email}</a>
          </span>
        </li>
      )}
    </ul>
  );
};

HelpContactInfo.propTypes = {
  name: PropTypes.string,
  mobile: PropTypes.string,
  email: PropTypes.string,
  countryCode: PropTypes.string,
};

HelpContactInfo.defaultProps = {
  countryCode: 'AU',
};

export default HelpContactInfo;
