import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import classNames from 'classnames/bind';
import defaultStyles from './ErrorMessage.css';

const ErrorMessage = ({ message, className }) => {
  const styles = classNames.bind(defaultStyles, [className])({
    root: true,
  });

  return (
    <div className={styles}>
      {renderHTML(
        typeof message === 'string' ? message : 'Unknown error occured...',
      )}
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ErrorMessage;
