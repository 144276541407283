import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Frame extends Component {
  static propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    sprite: PropTypes.string,
    scale: PropTypes.number,
    direction: PropTypes.string,
    wrapAfter: PropTypes.number,
    frame: PropTypes.number,
    spriteWidth: PropTypes.number,
    spriteHeight: PropTypes.number,
    isLoaded: PropTypes.bool,
  };

  static defaultProps = {
    scale: 1,
    direction: 'horizontal',
    frame: 0,
  };

  constructor(props) {
    super(props);

    this._widthRatio = props.width / props.scale;
    this._heightRatio = props.height / props.scale;

    this.baseStyle = {
      width: `${this._widthRatio}px`,
      height: `${this._heightRatio}px`,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isLoaded, spriteWidth, spriteHeight, scale, sprite } = nextProps;
    if (!this.props.isLoaded && isLoaded) {
      this.baseStyle.backgroundSize = `${spriteWidth / scale}px ${
        spriteHeight / scale
      }px`;
      this.baseStyle.backgroundImage = `url(${sprite})`;
    }
  }

  getSpritePosition() {
    const { direction, wrapAfter, frame } = this.props;
    const isHorizontal = direction === 'horizontal';

    let row;
    let col;

    if (isHorizontal) {
      row = Math.floor(frame / wrapAfter);
      col = frame % wrapAfter;
    } else {
      row = frame % wrapAfter;
      col = Math.floor(frame / wrapAfter);
    }

    const _width = -col * this._widthRatio;
    const _height = -row * this._heightRatio;
    return `${_width}px ${_height}px`;
  }

  render() {
    const { className, isLoaded } = this.props;

    const blockStyle = {
      ...this.baseStyle,
      backgroundPosition: isLoaded && this.getSpritePosition(),
    };
    return <div className={className} style={blockStyle} />;
  }
}

export default Frame;
