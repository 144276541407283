import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import partial from 'lodash/partial';

import { generateUniqueID } from '../../lib/utils';
import styles from './SectionTile.css';

const SectionTile = ({
  actionClass,
  actionIcon,
  actionText,
  disabled,
  iconName,
  id,
  image,
  imageAlt,
  onClick,
  title,
  titleDescription,
  theme,
}) => {
  const rootStyles = classNames.bind(styles)('summaryWrapper', theme, {
    disabled,
  });

  const iconStyles = partial(classNames, styles.icon);
  const descriptionStyle = classNames(styles.description);

  return (
    <div
      id={generateUniqueID('SectionTile', id)}
      className={rootStyles}
      onClick={disabled ? undefined : onClick}
    >
      <div className={styles.iconAndHeaderSection}>
        {iconName ? (
          <div className={iconStyles(iconName)} />
        ) : (
          <img src={image} alt={imageAlt} className={styles.image} />
        )}
        <div className={styles.textSection}>
          <h4>{title}</h4>
          <div className={descriptionStyle}>{titleDescription}</div>
        </div>
      </div>
      <div
        className={classNames(actionClass, styles.statusSection)}
        id='status'
      >
        {actionText && (
          <span className={styles.completeText}>{actionText}</span>
        )}
        <i className={actionIcon} />
      </div>
    </div>
  );
};

SectionTile.propTypes = {
  actionClass: PropTypes.string,
  actionIcon: PropTypes.string,
  actionText: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  titleDescription: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default SectionTile;
