import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './FullBgContentsWrapper.css';

const FullBgContentsWrapper = ({ title, children, theme }) => {
  const rootStyles = classNames.bind(styles)('root', theme);
  return (
    <div className={rootStyles}>
      <div className={styles.childrenWrapper}>
        <h1>{title}</h1>
        {children}
      </div>
    </div>
  );
};
FullBgContentsWrapper.displayName = 'FullBgContentsWrapper';
FullBgContentsWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  theme: PropTypes.string,
};

export default FullBgContentsWrapper;
