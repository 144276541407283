import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames/bind';
import Checkbox from '../Checkbox';
import Tooltip from '../Tooltip';
import { generateUniqueID } from '../../lib/utils';
import styles from './Checklist.css';

class Checklist extends Component {
  static displayName = 'Checklist';
  static propTypes = {
    id: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.object,
    action: PropTypes.func.isRequired,
    simplified: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    checkImage: PropTypes.string,
    circleImage: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      mouseoverItemKey: null,
    };
  }

  handleOnClick(item) {
    const { value, disabled, action } = this.props;

    if (!disabled) {
      const newValue = {
        ...value,
        [item.key]: !value[item.key],
      };
      action(newValue);
    }
  }

  onItemMouseEnter = (item) => () => {
    this.setState({
      mouseoverItemKey: item.key,
    });
  };

  onItemMouseLeave = () => {
    this.setState({
      mouseoverItemKey: null,
    });
  };

  renderItem = (item) => {
    const {
      id,
      disabled,
      value,
      inline,
      simplified,
      checkImage,
      circleImage,
    } = this.props;
    const { mouseoverItemKey } = this.state;

    const liStyle = classNames.bind(styles)({
      [styles.inlineItem]: !!inline,
      [styles.item]: !inline,
      [styles.disabled]: disabled,
    });

    return (
      <li
        id={`${id}--${item.key}`}
        className={liStyle}
        key={item.key}
        onClick={disabled ? null : () => this.handleOnClick(item)}
        onMouseEnter={isMobile || disabled ? null : this.onItemMouseEnter(item)}
        onMouseLeave={isMobile || disabled ? null : this.onItemMouseLeave}
      >
        <div id={`${id}--control--${item.key}`} className={styles.control}>
          <input className={styles.checkboxInput} type='checkbox' />
          <Checkbox
            className={styles.checkbox}
            selected={value[item.key]}
            disabled={disabled}
            mouseover={mouseoverItemKey === item.key}
            checkImage={checkImage}
            circleImage={circleImage}
          />
        </div>

        <div className={styles.prompt}>
          <div className={styles.row}>
            <span
              className={classNames(styles.label, {
                [styles.disabled]: disabled,
              })}
            >
              {simplified ? item.simplifiedLabel : item.label}
            </span>
            {inline && item.tooltip && (
              <Tooltip
                id={id}
                tooltip={item.tooltip}
                dataPlace='bottom'
                dataOffset='bottom'
              />
            )}
          </div>
          {!inline && !simplified && item.hint && (
            <div className={styles.hint}>{item.hint}</div>
          )}
        </div>
      </li>
    );
  };

  render() {
    const { id, items, simplified, inline, className } = this.props;

    const rootStyle = classNames.bind(styles)({
      root: true,
      simplified,
      inlineRoot: inline,
      [className]: !!className,
    });

    return (
      <ol id={generateUniqueID('Checklist', id)} className={rootStyle}>
        {items.map(this.renderItem)}
      </ol>
    );
  }
}

export default Checklist;
