import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import ClickOutsideCloseContent from './ClickOutsideCloseContent';

const DummiestComponent = onClickOutside(ClickOutsideCloseContent);
class ClickOutsideClose extends React.Component {
  static displayName = 'ClickOutsideClose';
  static propTypes = {
    children: PropTypes.node,
    theme: PropTypes.string,
  };

  static displayName = 'ClickOutsideClose';

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  hideContent() {
    this.setState({ isOpen: false });
  }

  // Called from ref (Jaryd's code)
  showContent() {
    this.setState({ isOpen: true });
  }

  render() {
    const { theme, children } = this.props;
    if (this.state.isOpen) {
      return (
        <DummiestComponent hide={() => this.hideContent()} theme={theme}>
          {children}
        </DummiestComponent>
      );
    }
    return null;
  }
}

export default ClickOutsideClose;
