import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressBar.css';

// newStyles: complete overwrite of the stylesheet, can be used when styles have nothing in common or deal with brand color
const ProgressBar = ({ percentage, newStyles = {} }) => {
  const progressBarStyle = {
    width: `${percentage}%`,
  };
  return (
    <div className={newStyles.root || styles.root}>
      <div
        className={newStyles.progressBar || styles.progressBar}
        style={progressBarStyle}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  newStyles: PropTypes.object,
};
export default ProgressBar;
