import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';

import styles from './Selection.css';

class Selection extends Component {
  static propTypes = {
    id: PropTypes.string,
    action: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectionDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultMessage: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    setFocusRef: PropTypes.func,
    className: PropTypes.string,
    simple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    nonTabbable: PropTypes.bool,
    disabled: PropTypes.bool,
    rootStyle: PropTypes.object,
    rootClassName: PropTypes.string,
    smallArrow: PropTypes.bool,
    selectionImage: PropTypes.string,
  };

  static contextTypes = {
    inactive: PropTypes.bool,
    errored: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    simple: false,
    fullWidth: false,
    disabled: false,
    smallArrow: false,
  };

  static renderItems(items) {
    return items.map((item) => {
      if (item.divider) {
        return (
          <option key='divider' disabled>
            ----------------
          </option>
        );
      }
      return (
        <option key={item.value} value={item.value}>
          {item.name}
        </option>
      );
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { action, value, selectionDefault } = this.props;

    if (!value && selectionDefault !== undefined) {
      action(selectionDefault);
    }
  }

  handleChange = (e) => {
    const { action, onChange } = this.props;
    action(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  renderDefault() {
    const { selectionDefault, defaultMessage } = this.props;
    if (!selectionDefault) {
      return (
        <option disabled value={''}>
          {defaultMessage}
        </option>
      );
    }
    return null;
  }

  render() {
    const {
      id,
      value,
      items,
      onFocus,
      setFocusRef,
      className,
      nonTabbable,
      rootStyle,
      rootClassName,
      disabled,
      simple,
      fullWidth,
      smallArrow,
      selectionImage,
    } = this.props;
    const { errored, inactive } = this.context;

    const wrapperClassName = classNames(styles.wrapper, rootClassName);
    const selectStyle = classNames.bind(
      styles,
      'root',
      className,
    )({
      errored,
      inactive,
      disabled,
      standard: !simple,
      activeStandard: !simple && !disabled && !inactive,
      simple,
      fullWidth,
    });

    const arrowClassName = classNames.bind(
      styles,
      'arrow',
    )({
      smallArrow: smallArrow || simple,
    });

    return (
      <div className={wrapperClassName} style={rootStyle}>
        <select
          id={id}
          ref={setFocusRef}
          className={selectStyle}
          value={value}
          onChange={this.handleChange}
          onFocus={onFocus}
          tabIndex={nonTabbable ? -1 : ''}
          disabled={disabled}
        >
          {this.renderDefault()}
          {Selection.renderItems(items)}
        </select>
        <SVGInline className={arrowClassName} svg={selectionImage} />
      </div>
    );
  }
}

export default Selection;
